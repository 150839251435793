import { useForm } from '@zupr/form'
import { t } from '@zupr/i18n'
import React, { useCallback, useEffect, useState } from 'react'

import { ReactComponent as CheckIcon } from '../../../../../svg/checkmark.svg'
import { ReactComponent as UserLocationPin } from '../../../../../svg/pin-outlined.svg'
import { ReactComponent as QuestionIcon } from '../../../../../svg/questionmark.svg'
import { useDomain } from '../../../../context/domain'
import { useShopper, useShopperLocation } from '../../../../context/shopper'
import { useIsMobile, useUx } from '../../../../context/ux'
import Badge from '../../../../shared/components/badge'
import Row, {
    CombinedFields,
    CombinedRows,
} from '../../../../shared/form/elements/row'
import Input from '../../../../shared/form/fields/input'
import { useShippingDetailsForm } from '../../../screens/checkout/hooks/shipping'
import GridBlockDetail from '../../grid/detail'
import Tray from '../../tray'

const ShopperLocationForm = ({ onSaved }) => {
    const { location, brand, type } = useDomain()
    const { addNotification } = useUx()
    const { distance, setDistance } = useShopperLocation()

    const { form, reset, validateAddress } = useShippingDetailsForm()

    const distanceForm = useForm({
        pause: true,
        fields: {
            distance: {
                type: 'string',
            },
        },
        values: {
            distance,
        },
    })

    const zipcode = form.getField('zipcode')
    const number = form.getField('number')
    const distanceField = distanceForm.getField('distance')

    const handleSave = useCallback(() => {
        validateAddress()
        setDistance(distanceField.value)
        onSaved()
        addNotification({
            level: 'success',
            message: t('Locatie opgeslagen'),
        })
    }, [
        validateAddress,
        setDistance,
        distanceField.value,
        onSaved,
        addNotification,
    ])

    return (
        <>
            <h3>{t('Your location')}</h3>
            <div className="tray-form">
                <p>
                    {type === 'location' &&
                        t(
                            'Weet direct of %{name} bij jou thuisbezorgt en wat de bezorgkosten zijn.',
                            'dutch',
                            {
                                name: brand || location?.title,
                            }
                        )}
                    {type === 'area' &&
                        t(
                            'Bekijk de winkels en producten bij jou in de buurt!',
                            'dutch'
                        )}
                </p>
                <GridBlockDetail>
                    <CombinedRows>
                        <Row field={zipcode}>
                            <Input field={zipcode} />
                        </Row>
                        <Row field={number}>
                            <Input field={number} />
                        </Row>
                    </CombinedRows>
                    <Row field={distanceField}>
                        <CombinedFields>
                            <Input
                                className="before-input"
                                field={distanceField}
                            />
                            <span className="after-input">{'km'}</span>
                        </CombinedFields>
                    </Row>
                </GridBlockDetail>
                <div className="grid-block-buttons">
                    <button
                        className="btn btn-success btn-large btn-radius-small"
                        onClick={handleSave}
                    >
                        {t('Save')}
                    </button>
                    {!!zipcode.value && (
                        <button
                            className="btn btn-large btn-radius-small"
                            onClick={reset}
                        >
                            {t('Locatie verwijder')}
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

const ShopperLocationButton = () => {
    const isMobile = useIsMobile()
    const [hasUserLocation, setHasUserLocation] = useState<
        boolean | undefined
    >()

    const {
        showShopperLocationForm,
        toggleShopperLocationForm,
        shippingDetails,
    } = useShopper()
    const { yourLocation } = useShopperLocation()

    const handleClose = useCallback(() => {
        toggleShopperLocationForm(false)
    }, [toggleShopperLocationForm])

    const handleOpen = useCallback(() => {
        toggleShopperLocationForm(true)
    }, [toggleShopperLocationForm])

    // move the bouncer into effect making it clientside only
    // as geolocating is client side only
    useEffect(() => {
        setHasUserLocation(!!shippingDetails.zipcode)
    }, [shippingDetails.zipcode])

    const hasZipcode = !!shippingDetails.zipcode

    return (
        <React.Fragment key={yourLocation}>
            <button
                className="inline"
                aria-label={yourLocation}
                onClick={handleOpen}
            >
                <span>
                    <UserLocationPin />
                    {typeof hasUserLocation === 'boolean' && (
                        <Badge small warning bounce={!hasUserLocation}>
                            {hasZipcode && <CheckIcon />}
                            {!hasZipcode && <QuestionIcon />}
                        </Badge>
                    )}
                </span>
                {!isMobile && <span>{yourLocation}</span>}
            </button>
            <Tray
                sidebar
                onClose={handleClose}
                open={showShopperLocationForm}
                right
            >
                <ShopperLocationForm onSaved={handleClose} key={yourLocation} />
            </Tray>
        </React.Fragment>
    )
}

export default ShopperLocationButton
